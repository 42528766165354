<template>
  <v-container>
    <h1 class="text-center">Список не найден</h1>
  </v-container>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>