<template>
  <v-container class="welcome_wrap">
    <h1 class="text-center align-self-center">Добро пожаловать</h1>
  </v-container>
</template>

<script>
export default {
  name: "WelcomePage"
}
</script>

<style scoped>
.welcome_wrap {
  display: flex;
  height: 70%;
  justify-content: center;
}
</style>